const middleware = {}

middleware['app'] = require('../../client/wineaccess/middleware/app.js')
middleware['app'] = middleware['app'].default || middleware['app']

middleware['inMobileApp'] = require('../../client/wineaccess/middleware/inMobileApp.js')
middleware['inMobileApp'] = middleware['inMobileApp'].default || middleware['inMobileApp']

middleware['metadata'] = require('../../client/wineaccess/middleware/metadata.js')
middleware['metadata'] = middleware['metadata'].default || middleware['metadata']

middleware['outsideReferralTracking'] = require('../../client/wineaccess/middleware/outsideReferralTracking.js')
middleware['outsideReferralTracking'] = middleware['outsideReferralTracking'].default || middleware['outsideReferralTracking']

middleware['route'] = require('../../client/wineaccess/middleware/route.js')
middleware['route'] = middleware['route'].default || middleware['route']

middleware['storeFilter'] = require('../../client/wineaccess/middleware/storeFilter.js')
middleware['storeFilter'] = middleware['storeFilter'].default || middleware['storeFilter']

middleware['user'] = require('../../client/wineaccess/middleware/user.js')
middleware['user'] = middleware['user'].default || middleware['user']

export default middleware
