import {
  isIntraStoreNavigation,
  isAnchorNavigation,
} from '~/../common/utils/url'

export default function (to, from, savedPosition) {
  if (isIntraStoreNavigation({ to: to.path, from: from.path })) {
    return null
  } else if (isAnchorNavigation(to, from)) {
    return null
  }
  return { x: 0, y: 0 }
}
