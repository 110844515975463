const { isProductionBuild, isDev1Build } = require('~/config/build.js')
export default () => {
  if (process.client) {
    // manually add the script to the DOM
    const script = document.createElement('script')
    if (isDev1Build()) {
      script.src = '/js/wa_nr.dev.js'
    } else if (isProductionBuild()) {
      script.src = '/js/wa_nr.js'
    }
    script.async = true
    document.head.appendChild(script)
  }
}
