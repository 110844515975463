const keys = require('~/config/keys.js')
export default () => {
  if (process.client) {
    // manually add the script to the DOM
    const facebookInit = () => {
      return `window.fbAsyncInit = function() {
        FB.init({
          appId      : ${keys.facebook.appId},
          xfbml      : ${keys.facebook.xbfml},
          version    : ${keys.facebook.version},
        });
      };

      (function(d, s, id){
         var js, fjs = d.getElementsByTagName(s)[0];
         if (d.getElementById(id)) {return;}
         js = d.createElement(s); js.id = id;
         js.async = true;
         js.src = "//connect.facebook.net/en_US/sdk.js";
         fjs.parentNode.insertBefore(js, fjs);
       }(document, 'script', 'facebook-jssdk'));`
    }
    const script = document.createElement('script')
    script.innerHTML = facebookInit()
    document.body.appendChild(script)
  }
}
